<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-title>{{ pageTitle }}</v-card-title>
                    <v-card-subtitle>{{ pageSubtitle }}</v-card-subtitle>
                    <v-card-text v-if="promoCode != null">
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="6">
                                    <div class="subheading grey--text">
                                        General Information
                                    </div>

                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-text-field v-model="promoCode.code" label="Code" :rules="[ rules.required ]" :disabled="isEdit"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="promoCode.description" label="Description" :rules="[ rules.required ]"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field v-model="promoCode.amount" label="Discount amount" :rules="[ rules.required ]" prefix="$"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field v-model="promoCode.minAmount" label="Order min. amount" prefix="$" @keydown="numbersOnly($event)"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field v-model="promoCode.maxAmount" label="Order max. amount" prefix="$" @keydown="numbersOnly($event)"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-switch v-model="promoCode.canUseWithOthers" label="Can be used with other promo codes"></v-switch>
                                    <v-switch v-model="promoCode.needComment" label="Requires a comment from the user"></v-switch>
                                    <v-switch v-model="promoCode.onlyManagers" label="Can only be used by store manager"></v-switch>
                                    <v-switch v-model="promoCode.active" label="Active"></v-switch>
                                </v-col>
                            </v-row>
                        </v-form>

                        <div class="text-right mt-7">
                            <v-btn depressed color="grey darken-4" class="white--text" @click="save" :loading="saving">Save</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="promo" :id="code"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import History from '@/components/History.vue';
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        code: { type: [ String ], default: null }
    },
    data: () => ({
        promoCode           : null,
        loading             : false,
        saving              : false,

        rules               : {
            required        : v => !!v || 'required',
        },
    }),
    computed: {
        pageTitle() {
            if( _st.isNUE( this.code ) || this.code == 'new' ) {
                return 'Create new promo code';
            }
            
            return 'Edit promo code';
        },
        pageSubtitle() {
            if( _st.isNUE( this.code ) ) {
                return 'Create a new promo code to be used within the LFA Rental App';
            }
            else {
                return 'Edit all the details of the promo code';
            }
        },
        isEdit() {
            return !_st.isNUE( this.code ) && this.code !== 'new';
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            savePromoCode: 'promo/savePromoCode'
        }),
        async init() {
            try {
                this.cleanObject();

                if( _st.isNUE( this.code ) || this.code == 'new' ) {
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/admin/promo-code/${this.code}`);
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( res.data.data.code ) )
                    this.$router.push('/404');
                
                this.promoCode = res.data.data;

                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        cleanObject() {
            this.promoCode = {
                code                : "",
                description         : "",
                canUseWithOthers    : false,
                amount              : 0,
                minAmount           : 0,
                maxAmount           : 0,
                minItems            : 0,
                maxItems            : 0,
                needComment         : false,
                onlyManagers        : false,
                active              : false,
                comment             : ""
            };
        },
        async save() {
            if( !this.$refs.form.validate() )
                return;

            try {
                this.saving = true;
                let res = await this.savePromoCode({ promoCode: this.promoCode, isEdit: this.isEdit });
                this.saving = false;

                if( res.status === false ) {
                    this.mxShowMessage( res.message, res.error ? 'error' : 'warning' );
                    return;
                }

                if( this.isEdit ) {
                    this.promoCode = res.data;
                    this.promoCode.amount = this.formatNumber( this.promoCode.amount, 2 );
                    this.promoCode.minAmount = this.formatNumber( this.promoCode.minAmount, 2 );
                    this.promoCode.maxAmount = this.formatNumber( this.promoCode.maxAmount, 2 );
                }
                else {
                    this.$router.push(`/promo/${res.data.code}`);
                }
                
                this.mxShowMessage( 'Promo code saved', 'success' );
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        }
    },
    components: {
        History
    }
}
</script>

<style lang="scss" scoped>

</style>